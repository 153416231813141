
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import useDealerPayout from "@/hooks/payment/Mgmt/PendingApproval/PayoutRound/useDealerPayout";
import EntityWrapper from "@/views/Payment/Mgmt/components/entityWrapper.vue";
import Entity from "@/views/Payment/Mgmt/components/entity.vue";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
export default defineComponent({
  name: "paymentTracking",
  props: {},
  setup() {
    const route = useRoute();
    const {
      payoutTrackingList,
      getPayoutTrackingList,
      getRowClass,
    } = useDealerPayout();

    //#region [payround下拉列表相关]
    getPayoutTrackingList();
    const payoutRound = ref<string | null>(
      route.query.payoutRound as string | null
    );
    const handlePayroundChange = (val: string) => {
      payoutRound.value = val;
    };
    //#endregion
    return {
      payoutRound,
      payoutTrackingList,
      handlePayroundChange,
      getRowClass,
      toThousandsAndTwoDecimal
    };
  },
  components: {
    EntityWrapper,
    Entity
  },
});
